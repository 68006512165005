// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("/opt/build/repo/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-danke-tsx": () => import("/opt/build/repo/src/pages/danke.tsx" /* webpackChunkName: "component---src-pages-danke-tsx" */),
  "component---src-pages-imprint-tsx": () => import("/opt/build/repo/src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("/opt/build/repo/src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-privacy-tsx": () => import("/opt/build/repo/src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-projekt-tsx": () => import("/opt/build/repo/src/pages/projekt.tsx" /* webpackChunkName: "component---src-pages-projekt-tsx" */),
  "component---src-pages-spenden-tsx": () => import("/opt/build/repo/src/pages/spenden.tsx" /* webpackChunkName: "component---src-pages-spenden-tsx" */),
  "component---src-pages-team-tsx": () => import("/opt/build/repo/src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

